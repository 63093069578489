<template>
    <b-row class="p-5 m-0">
        <h1 class="p-0 m-0">SC Livre</h1>

        <div style="height: 100vh">
        </div>
    </b-row>
</template>
<script>
export default {
    name: "SclivreView",
    created() {
        document.title = 'Isadora Piana - SC livre';
    }
}
</script>
<style lang="scss" scoped>
</style>

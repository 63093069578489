<template>
    <div id="app">
        <navbar-component/>

        <div class="spacer"/>

        <router-view/>

        <div class="spacer"/>

        <footer-component/>
    </div>
</template>

<script>
import NavbarComponent from "@/components/navbar/NavbarComponent";
import FooterComponent from "@/components/footer/FooterComponent";

export default {
    components: {
        FooterComponent,
        NavbarComponent
    }
}
</script>

<style lang="scss">
@import "@/main.scss";

html, body {
    height: 100%;
}

#app {
    // font-family: 'Montserrat', Avenir, Helvetica, Arial, sans-serif;
    font-family: 'FF Mark', serif;
    letter-spacing: 1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background-color: $cor-clara;
    color: $cor-escura;

    display: flex;
    flex-direction: column;
    min-height: 100%;

    .spacer {
        flex: 1;
    }

    a {
        color: $cor-clara;
        text-decoration: none;
        text-align: center;

        &:hover {
            color: $cor-clara-transparente;
        }
    }
}


</style>
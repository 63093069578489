<template>
    <div>
        <banner-component/>

        <informacoes-component :texto="texto" font-size="xx-large"/>

        <sobre-view/>

<!--        <b-row class="mt-5 m-0 p-0">-->
<!--            <quadrado-component v-for="(conteudo, index) in conteudos" :key="index" :conteudo="conteudo"/>-->
<!--        </b-row>-->

    </div>
</template>

<script>
import BannerComponent from "@/components/home/BannerComponent";
import InformacoesComponent from "@/components/home/InformacoesComponent";
import QuadradoComponent from "@/components/home/QuadradoComponent";
import SobreView from "@/views/SobreView";

export default {
    name: 'HomeView',
    data() {
        return {
            imagens: [
                "image-1.jpeg",
                "image-5.jpeg",
                "image-6.jpeg",
            ],
            conteudos: [
                {titulo: "Sobre mim", rota: "sobre", imagem: "image-1.jpeg"},
                // {titulo: "SC Livre", rota: "sclivre", imagem: "image-3.jpeg"},
                {titulo: "Renova", rota: "renova", imagem: "image-5.jpeg"},
                // {titulo: "NOVO", rota: "novo", imagem: "image-5.jpeg"},
                // {titulo: "Artigos", rota: "artigos", imagem: "image-6.jpeg"},
                {titulo: "Contato", rota: "contato", imagem: "image-6.jpeg"},
            ],
            texto: [
                "Pré-candidata a Deputada Estadual pelo Partido NOVO em Santa Catarina",
                "Precisamos de líderes comprometidos em diminuir burocracias e o custo do Estado para a população",
            ],
        }
    },
    components: {
        SobreView,
        InformacoesComponent,
        BannerComponent,
        QuadradoComponent,
    },
    created() {
        document.title = 'Isadora Piana - Home';

        this.imagens.sort(() => Math.random() - 0.5)

        this.conteudos[0].imagem = this.imagens[0];
        this.conteudos[1].imagem = this.imagens[1];
        this.conteudos[2].imagem = this.imagens[2];
    },
}
</script>

<style lang="scss" scoped>
@import "@/main.scss";

#app {
    color: $cor-laranja;
}

</style>
<template>
    <b-col class="col-sm-6 col-md-4 m-0 p-0">
        <div v-for="(post, index) in posts" :key="index" class="mt-5 mb-5">
            <a :href="post.permalink" target="_blank">
                <img :src="post.media_url" alt="" class="img-fluid">
            </a>
            <h2>{{ post.caption }}</h2>
        </div>
    </b-col>
</template>

<script>
import axios from "axios";

export default {
    name: "InstagramComponent",
    data() {
        return {
            info: null,
            posts: []
        }
    },
    mounted() {
        this.funcao();
    },
    computed:{
        async getPosts(token, idPost) {
            await axios
                .get('https://graph.instagram.com/' + idPost + '?fields=media_type,media_url,permalink,caption&access_token=' + token)
                .then(response => (this.info = response))

            console.log(this.info)

            let aux = Object();

            aux.media_type = this.info.data.media_type;
            aux.media_url = this.info.data.media_url;
            aux.permalink = this.info.data.permalink;
            aux.caption = this.info.data.caption;

            this.posts.push(aux);
        },

        async funcao() {
            let token = 'IGQVJVLWNEckV4dkZA0b0xDY2pEMmZA0a19jMnJsLVVEVkFDLTZAXMmptWHlZAT0Ntd3cyZAmRVXzdBQVdCMTFjUFdvaFA4VzlBZA2xKSXc1MUdGSG0wSl9nMEpvX0M2cjVScmtOYkdqV0s0Y29tNm5aQ01tcwZDZD';

            await axios
                .get('https://graph.instagram.com/me?fields=username,media&access_token=' + token)
                .then(response => (this.info = response))

            for (const post of this.info.data.media.data) {
                await this.getPosts(token, post.id)
            }
        },
    },
    methods: {

    }
}

</script>

<style scoped>

</style>
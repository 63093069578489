import Vue from 'vue'
import App from './App.vue'
import router from './router'

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

let app = new Vue({
    router,
    render: function (h) {
        return h(App)
    }
});

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

app.$mount('#app');
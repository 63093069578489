<template>
    <div>

        <div v-if="nome_icone.toLowerCase() === 'tiktok'">
            <svg :height="tamanho_icones" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m412.19 118.66a109.27 109.27 0 0 1 -9.45-5.5 132.87 132.87 0 0 1 -24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1c-2.08-12.21-1.22-20.11-1.09-20.11h-82.44v318.78c0 4.28 0 8.51-.18 12.69 0 .52-.05 1-.08 1.56 0 .23 0 .47-.05.71v.18a70 70 0 0 1 -35.22 55.56 68.8 68.8 0 0 1 -34.11 9c-38.41 0-69.54-31.32-69.54-70s31.13-70 69.54-70a68.9 68.9 0 0 1 21.41 3.39l.1-83.94a153.14 153.14 0 0 0 -118 34.52 161.79 161.79 0 0 0 -35.3 43.53c-3.48 6-16.61 30.11-18.2 69.24-1 22.21 5.67 45.22 8.85 54.73v.2c2 5.6 9.75 24.71 22.38 40.82a167.53 167.53 0 0 0 35.71 33.69v-.2l.2.2c39.91 27.12 84.16 25.34 84.16 25.34 7.66-.31 33.32 0 62.46-13.81 32.32-15.31 50.72-38.12 50.72-38.12a158.46 158.46 0 0 0 27.64-45.93c7.46-19.61 9.95-43.13 9.95-52.53v-169.12c1 .6 14.32 9.41 14.32 9.41s19.19 12.3 49.13 20.31c21.48 5.7 50.42 6.9 50.42 6.9v-81.84c-10.14 1.1-30.73-2.1-51.81-12.61z"
                    fill="currentColor"/>
            </svg>
        </div>

        <div v-else-if="nome_icone.toLowerCase() === 'facebook'">
            <svg :height="tamanho_icones" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29v-156.52h-56.89v-64.77h56.89v-49.35c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13h-28.26c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77h-52.19v156.54c107.1-16.81 189-109.48 189-221.31z"
                    fill="currentColor"/>
            </svg>
        </div>

        <div v-else-if="nome_icone.toLowerCase() === 'whatsapp'">
            <svg :height="tamanho_icones" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m414.73 97.1a222.14 222.14 0 0 0 -157.79-65.1c-122.94 0-223.02 99.58-223.07 222a220.61 220.61 0 0 0 29.78 111l-31.65 115 118.25-30.87a223.63 223.63 0 0 0 106.6 27h.09c122.93 0 223-99.59 223.06-222a220.18 220.18 0 0 0 -65.27-157.03zm-157.79 341.56h-.08a185.75 185.75 0 0 1 -94.36-25.72l-6.77-4-70.17 18.32 18.73-68.09-4.41-7a183.46 183.46 0 0 1 -28.35-98.17c0-101.73 83.21-184.5 185.48-184.5a185 185 0 0 1 185.33 184.64c-.04 101.74-83.21 184.52-185.4 184.52zm101.69-138.19c-5.57-2.78-33-16.2-38.08-18.05s-8.83-2.78-12.54 2.78-14.4 18-17.65 21.75-6.5 4.16-12.07 1.38-23.54-8.63-44.83-27.53c-16.57-14.71-27.75-32.87-31-38.42s-.35-8.56 2.44-11.32c2.51-2.49 5.57-6.48 8.36-9.72s3.72-5.56 5.57-9.26.93-6.94-.46-9.71-12.54-30.08-17.18-41.19c-4.53-10.82-9.12-9.35-12.54-9.52-3.25-.16-7-.2-10.69-.2a20.53 20.53 0 0 0 -14.86 6.94c-5.11 5.56-19.51 19-19.51 46.28s20 53.68 22.76 57.38 39.3 59.73 95.21 83.76a323.11 323.11 0 0 0 31.78 11.68c13.35 4.22 25.5 3.63 35.1 2.2 10.71-1.59 33-13.42 37.63-26.38s4.64-24.06 3.25-26.37-5.11-3.71-10.69-6.48z"
                    fill="currentColor"/>
            </svg>
        </div>

        <div v-else-if="nome_icone.toLowerCase() === 'twitter'">
            <svg :height="tamanho_icones" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m496 109.5a201.8 201.8 0 0 1 -56.55 15.3 97.51 97.51 0 0 0 43.33-53.6 197.74 197.74 0 0 1 -62.56 23.5 99.14 99.14 0 0 0 -71.91-30.7c-54.42 0-98.46 43.4-98.46 96.9a93.21 93.21 0 0 0 2.54 22.1 280.7 280.7 0 0 1 -203-101.3 95.69 95.69 0 0 0 -13.39 48.7c0 33.6 17.53 63.3 44 80.7a97.5 97.5 0 0 1 -44.78-12.1v1.2c0 47 34 86.1 79 95a100.76 100.76 0 0 1 -25.94 3.4 94.38 94.38 0 0 1 -18.51-1.8c12.51 38.5 48.92 66.5 92.05 67.3a199.59 199.59 0 0 1 -122.32 41.5 203 203 0 0 1 -23.5-1.4 278.68 278.68 0 0 0 150.74 43.8c181.36 0 280.44-147.7 280.44-275.8 0-4.2-.11-8.4-.31-12.5a198.48 198.48 0 0 0 49.13-50.2z"
                    fill="currentColor"/>
            </svg>
        </div>

        <div v-else-if="nome_icone.toLowerCase() === 'instagram'">
            <svg :height="tamanho_icones" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m349.33 69.33a93.62 93.62 0 0 1 93.34 93.34v186.66a93.62 93.62 0 0 1 -93.34 93.34h-186.66a93.62 93.62 0 0 1 -93.34-93.34v-186.66a93.62 93.62 0 0 1 93.34-93.34zm0-37.33h-186.66c-71.87 0-130.67 58.8-130.67 130.67v186.66c0 71.87 58.8 130.67 130.67 130.67h186.66c71.87 0 130.67-58.8 130.67-130.67v-186.66c0-71.87-58.8-130.67-130.67-130.67z"
                    fill="currentColor"/>
                <path d="m377.33 162.67a28 28 0 1 1 28-28 27.94 27.94 0 0 1 -28 28z"
                      fill="currentColor"/>
                <path
                    d="m256 181.33a74.67 74.67 0 1 1 -74.67 74.67 74.75 74.75 0 0 1 74.67-74.67m0-37.33a112 112 0 1 0 112 112 112 112 0 0 0 -112-112z"
                    fill="currentColor"/>
            </svg>
        </div>

    </div>
</template>

<script>
export default {
    name: "icones-component",
    data() {
        return {
            tamanho_icones: 32,
        }
    },
    props: {
        nome_icone: String
    }
}
</script>

<style lang="scss" scoped>

@import "@/main.scss";

svg {
    color: $cor-laranja;

    &:hover {
        color: $cor-laranja-transparente;
    }
}

</style>
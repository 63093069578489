import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SclivreView from "@/views/SclivreView";
import RenovaView from "@/views/RenovaView";
import SobreView from "@/views/SobreView";
import NovoView from "@/views/NovoView";
import ArtigosView from "@/views/ArtigosView";
import ContatoView from "@/views/ContatoView";
import PaginaNaoEncontradaView from "@/views/PaginaNaoEncontradaView";
import ProjetosView from "@/views/ProjetosView";

Vue.use(VueRouter)

const routes = [
    {path: '/', name: 'home', component: HomeView},
    {path: '/sclivre', name: 'sclivre', component: SclivreView},
    {path: '/renova', name: 'renova', component: RenovaView},
    {path: '/sobre', name: 'sobre', component: SobreView},
    {path: '/novo', name: 'novo', component: NovoView},
    {path: '/projetos', name: 'projetos', component: ProjetosView},
    {path: '/artigos', name: 'artigos', component: ArtigosView},
    {path: '/contato', name: 'contato', component: ContatoView},
    {path: '*', name: 'paginaNaoEncontrada', component: PaginaNaoEncontradaView},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router

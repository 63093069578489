<template>
    <div>
        <div class="row fundo p-0 m-0">

            <div class="d-flex align-items-end ms-5">
                <p class="nome px-4 m-1 d-none d-lg-block"><strong>Isadora Piana</strong></p>
            </div>

            <div class="d-flex align-items-start ms-5">
                <p class="descricao px-4 m-1 d-none d-lg-block"><strong>Pré-candidata a Deputada Estadual</strong></p>
            </div>

        </div>

        <!--        <b-row align-content="center" class="m-0 fundo">-->
        <!--            <b-col/>-->
        <!--&lt;!&ndash;            <p class="nome px-5 col-12 col-md-auto">Isadora Piana<br/>Pré-candidata a Deputada Estadual</p>&ndash;&gt;-->

        <!--            <b-col/>-->
        <!--            <b-col/>-->
        <!--            <b-col/>-->
        <!--            <b-col/>-->
        <!--        </b-row>-->
    </div>
</template>

<script>
export default {
    name: "BannerComponent"
}
</script>

<style lang="scss" scoped>
@import "@/main.scss";

.fundo {
    // background: url("https://images.contentstack.io/v3/assets/blt187521ff0727be24/blta47d8f0561d0d309/60ee0e698a77034ffea2331a/kaisa-comic-bg.jpg") no-repeat top center;
    //background: url("@/assets/banner.jpeg") no-repeat top center;
    background: url("@/assets/banner-2-sem-fundo.png") no-repeat center center;
    background-size: contain;
    //background-position-y: 0;
    height: 100vh;
}

.nome {
    //color: $cor-laranja;
    color: $cor-escura;
    //background-color: $cor-clara-transparente;
    border-left: $cor-laranja solid 5px;
    font-size: 6vh;
}

.descricao {
    //color: $cor-laranja;
    color: $cor-escura;
    //background-color: $cor-escura-transparente;
    border-left: $cor-laranja solid 5px;
    font-size: 2.5vh;
}
</style>
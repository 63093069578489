<template>
    <b-row class="p-5 m-0">
        <h1 class="p-0 m-0">Artigos</h1>
        <div style="">

            <b-img :src="require('@/assets/' +'image-' + get()+ '.jpeg')" alt="imagem" class="p-0 m-0 " fluid/>

        </div>
    </b-row>
</template>
<script>
export default {
    name: "ArtigosView",
    created() {
        document.title = 'Isadora Piana - Artigos';
    },
    methods: {
        get() {
            return Math.floor(Math.random() * 5) + 1;
        }
    }
}
</script>
<style lang="scss" scoped>
</style>
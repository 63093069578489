<template>
        <b-row class="m-5 p-0">
            <b-col v-for="(text, index) in texto" :key="index"
                   class="m-0 p-3 col-12 col-lg-6 d-flex justify-content-center">

                <div class="info d-flex align-items-center">
                    <p :style="'font-size: ' + fontSize" class="m-3 p-0 texto">
                        {{ text }}
                    </p>
                </div>
            </b-col>
        </b-row>
</template>

<script>
export default {
    name: "InformacoesComponent",
    data() {
        return {
            titulo: "titulo"
        }
    },
    props: {
        texto: Array,
        fontSize: {
            default: "medium",
            type: String,
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/main.scss";

.info {
    border-left: $cor-laranja solid 4px;
    border-bottom: $cor-laranja-transparente solid 2px;
}
</style>
<template>
    <b-row class="p-5 m-0">

        <b-row class="p-0 m-0 flex">
            <b-col/>

            <b-col class="p-0 m-0 col-12 col-md-10 col-lg-8">
                <div v-for="(link, index) in links" :key="index" class="p-3">
                    <h3 class="p-0 m-0">
                        <a :href="link.url" target="_blank">{{ link.nome }}</a>
                    </h3>
                </div>
            </b-col>

            <b-col/>
        </b-row>

    </b-row>
</template>
<script>
export default {
    name: "ContatoView",
    data() {
        return {
            links: [
                {nome: "Seja um voluntário", url: "https://forms.gle/GYbGrZRPUV5NAYrN8"},
                {nome: "Whatsapp", url: "https://wa.me/message/IDKXVQTEYOHRE1"},
                {nome: "Instagram", url: "https://www.instagram.com/isadorapiana.sc/"},
                {nome: "Facebook", url: "https://www.facebook.com/isadorapiana.sc"},
                {nome: "Twitter", url: "https://twitter.com/isadorapiana"},
                {nome: "TikTok", url: "https://www.tiktok.com/@isadorapiana"},
                {nome: "Movimento #SCLivre", url: "https://www.sclivre.com.br/"},
            ]
        }
    },
    created() {
        document.title = 'Isadora Piana - Contato';
    }
}
</script>
<style lang="scss" scoped>
@import "@/main.scss";

#app {
    a {
        color: $cor-escura;

        &:hover {
            color: $cor-escura-transparente;
        }
    }
}
</style>
<template>
    <b-row class="p-3 m-0">

        <b-row>
            <b-col v-for="(rede, index) in redes" :key="index" class="p-3">
                <div class="d-inline-flex">
                    <a :href="rede.url" target="_blank">
                        <icones-component :nome_icone="rede.nome" />
                    </a>
                </div>
            </b-col>

        </b-row>

        <h5 class="p-0 m-0">© 2022 Isadora Piana</h5>
    </b-row>
</template>

<script>
import IconesComponent from "@/components/icones/icones-component";
export default {
    name: "FooterComponent",
    components: {IconesComponent},

    data() {
        return {
            redes: [
                {nome: "Instagram", url: "https://www.instagram.com/isadorapiana.sc/"},
                {nome: "Whatsapp", url: "https://wa.me/message/IDKXVQTEYOHRE1"},
                {nome: "Facebook", url: "https://www.facebook.com/isadorapiana.sc"},
                {nome: "Twitter", url: "https://twitter.com/isadorapiana"},
                {nome: "Tiktok", url: "https://www.tiktok.com/@isadorapiana"},
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/main.scss";
#app {
    a {
        color: $cor-escura;
        &:hover {
            color: $cor-escura-transparente;
        }
    }
}
</style>
<template>
    <div class="p-3 m-0">
        <div class="info p-0 m-0">
            <h3 v-if="titulo != null" class="ms-3"><strong>{{ titulo }}:</strong></h3>
            <p :style="'font-size: ' + fontSize" class="m-3 p-0 texto">
                {{ texto }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "InformacaoComponent",
    data() {
        return {}
    },
    props: {
        titulo: String,
        texto: String,
        fontSize: {
            default: "large",
            type: String,
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/main.scss";

.info {
    border-left: $cor-laranja solid 4px;
    border-bottom: $cor-laranja-transparente solid 2px;
    color: $cor-escura;
}
</style>
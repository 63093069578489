<template>
    <div class="sticky-top">

        <b-navbar class="px-2" toggleable="lg" type="light">

            <div style="width: 150px">
                <b-navbar-brand class="p-2 text-start" href="#" tabindex="-1" to="/">
                    Isadora Piana
                </b-navbar-brand>
            </div>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="m-auto">

                    <b-nav-item to="/">Home</b-nav-item>
                    <!--                    <b-nav-item to="/sclivre">SC Livre</b-nav-item>-->
                    <!--                    <b-nav-item to="/renova">Renova</b-nav-item>-->
                    <!--                    <b-nav-item to="/novo">NOVO</b-nav-item>-->
                    <!--                    <b-nav-item to="/artigos">Artigos</b-nav-item>-->
                    <!--                    <b-nav-item to="/sobre">Sobre mim</b-nav-item>-->
                    <b-nav-item to="/projetos">Projetos</b-nav-item>
                    <b-nav-item href="https://isadorapiana.financie.de" target="_blank">Contribua</b-nav-item>

                    <div style="width: 150px"/>
                </b-navbar-nav>
            </b-collapse>

        </b-navbar>
    </div>
</template>

<script>
export default {
    name: "NavbarComponent"
}
</script>

<style lang="scss" scoped>
@import "@/main.scss";

#app a {
    color: $cor-laranja;
    font-weight: bold;

    &:hover {
        color: $cor-laranja-transparente;
    }
}

nav {
    background-color: $cor-clara;
}

</style>
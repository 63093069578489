<template>
    <div>
        <h1 class="p-5 m-0">Sobre mim</h1>

        <b-row class="p-0 m-0 m">
            <b-col/>

            <b-col class="p-0 m-0 col-12 col-md-10 col-lg-8">
                <b-row class="p-0 m-0">

                    <b-col class="p-0 m-0 col-12 col-lg-7">
                        <informacao-component v-for="(text, index) in texto" :key="index" :texto="text"
                                              font-size="x-large"/>
                    </b-col>

                    <b-col class="p-0 m-0 col-12 col-lg-5 d-flex align-items-center">
                        <b-img :src="require('@/assets/image-renova.jpeg')" alt="renova" class="p-0 m-0" fluid/>
                    </b-col>

                </b-row>
            </b-col>

            <b-col/>
        </b-row>

    </div>
</template>
<script>
import InformacaoComponent from "@/components/home/InformacaoComponent";

export default {
    name: "SobreView",
    components: {
        InformacaoComponent,
    },
    data() {
        return {
            texto: [
                "Natural de Campos Novos, filha mais nova de um casal de agricultores, João e Jandira, irmã de quatro empreendedores Vanderlei, Eliza, Francieli e Karize.",
                "Acadêmica do curso de Direito na UNOESC. Trabalhei em áreas de gestão, finanças e justiça.",
                // "Iniciei minha carreira aos 13 anos, me tornando o financeiro da instituição aos 16 anos.",
                "Estagiei na Defensoria Pública, em seguida na Vara Criminal do Fórum da Comarca de Caçador. Trabalhei no administrativo do SICOOB.",
                "Apaixonada pelo meio-oeste, escolhi a política por vocação e acredito que ela serve para colocar meus valores em prática!",
            ]
        }
    },
    created() {
        document.title = 'Isadora Piana - Sobre';
    }
}
</script>

<style lang="scss" scoped>
@import "@/main.scss";
</style>
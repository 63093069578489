<template>
    <b-row class="p-5 m-0">
        <h1 class="p-0 m-0">Novo</h1>
        <div style="height: 100vh">
        </div>
    </b-row>
</template>
<script>
export default {
    name: "NovoView",
    created() {
        document.title = 'Isadora Piana - NOVO';
    }
}
</script>
<style lang="scss" scoped>

</style>
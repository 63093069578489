<template>

    <div>
        <h1 class="p-5 m-0">Projeto Renova<br/>Meio Oeste</h1>

        <b-row class="p-0 m-0 m">
            <b-col/>

            <b-col class="p-0 m-0 col-12 col-md-10 col-lg-8">
                <b-row class="p-0 m-0">

                    <b-row class="p-0 m-0">
                        <b-col class="p-0 m-0 col-12 col-lg-8 d-flex flex-column justify-content-center">
                            <informacao-component v-for="(text, index) in texto1" :key="index" :texto="text"
                                                  font-size="x-large"/>
                        </b-col>

                        <b-col class="p-0 m-0 col-12 col-lg-4 d-flex flex-column justify-content-center">
                            <b-img :src="require('@/assets/image-renova.jpeg')" alt="renova" class="p-0 m-0" fluid/>
                        </b-col>
                    </b-row>

                    <b-row class="m-5 p-0"/>

                    <b-row class="p-0 m-0 d-flex align-items-stretch">
                        <b-col v-for="(text, index) in texto2" :key="index"
                               class="p-3 m-0 col-12 col-lg-6 d-flex align-items-stretch">

                            <div class="borda flex-fill d-flex flex-column p-3">
                                <h2><strong>{{ text.titulo }}</strong></h2>

                                <div class="p-0 m-1 d-flex flex-fill align-items-center justify-content-center">
                                    <p style="font-size: x-large">{{ text.texto }}</p>
                                </div>
                            </div>
                        </b-col>
                    </b-row>

                </b-row>
            </b-col>

            <b-col/>
        </b-row>

    </div>

    <!--    <b-row class="p-5 m-0">-->
    <!--        <b-row>-->
    <!--            <b-col class="col-1"/>-->
    <!--            <b-col class="row">-->


    <!--                <b-col>-->
    <!--                    <informacoes-component :texto="texto1" font-size="x-large"/>-->
    <!--                </b-col>-->

    <!--                <b-img :src="require('@/assets/image-renova.jpeg')" alt="renova" class="p-0 m-0 col-3" fluid/>-->

    <!--                <b-row class="m-0 my-5 p-0 py-5 d-flex">-->
    <!--                    <informacao-component v-for="(texto, index) in texto2" :key="index" :texto="texto.texto"-->
    <!--                                          :titulo="texto.titulo" font-size="x-large"/>-->
    <!--                </b-row>-->

    <!--            </b-col>-->
    <!--            <b-col class="col-1"/>-->
    <!--        </b-row>-->

    <!--    </b-row>-->
</template>
<script>
import InformacoesComponent from "@/components/home/InformacoesComponent";
import InformacaoComponent from "@/components/home/InformacaoComponent";

export default {
    name: "RenovaView",
    components: {InformacaoComponent, InformacoesComponent},
    data() {
        return {
            texto1: [
                "Renovar a representatividade política do meio oeste de Santa Catarina, a fim de eleger líderes comprometidos com a economia de dinheiro público, renúncia de privilégios, transparência e fiscalização.",
            ],
            texto2:
                [
                    // {titulo: null, texto: "Defesa as liberdades individuais"},
                    {titulo: "Combate à corrupção e desperdícios", texto: "Fiscalizar os atos públicos"},
                    {titulo: "Economia", texto: "Todos os atos planejados serão executados de maneira econômica"},
                    {titulo: "Coerência", texto: "Fazer e ser o que propagamos"},
                    {titulo: "Responsabilidade", texto: "Cumprir com as tarefas do início ao fim"},
                    {titulo: "Proatividade", texto: "Trazer inovações ao projeto e assumir tarefas espontaneamente"},
                    {titulo: "Transparência", texto: "Prestar contas aos demais integrantes e apoiadores"},
                    {titulo: "Apoio ao empreendedorismo", texto: "Desburocratizar e agir em prol dos empreendedores"},
                    {
                        titulo: "Educação como ferramenta de inclusão social",
                        texto: "Levar os conhecimentos adquiridos dentro do Projeto a outras pessoas"
                    },
                ]
        }
    },
    created() {
        document.title = 'Isadora Piana - Renova';
    }
}
</script>
<style lang="scss" scoped>
</style>
<template>
    <b-row class="p-5 m-0 fundo1">
        <h1 class="p-0 m-0">404 - Página não encontrada</h1>
        <div style="height: 100vh">
        </div>
    </b-row>
</template>
<script>
export default {
    name: "PaginaNaoEncontradaView",
    created() {
        document.title = 'Isadora Piana - 404';
    }
}
</script>
<style lang="scss" scoped>
</style>
<template>
    <div class="col-12 col-sm-6 col-lg-4 m-0 p-0">

        <router-link :to="'/'+conteudo.rota" tabindex="-1">

            <div class="container m-0 p-0" @mouseleave="upHere = false" @mouseover="upHere = true">

                <transition name="fade">
                    <div v-show="upHere" class="teste"/>
                </transition>

                <b-img :src="require('@/assets/' + conteudo.imagem)" alt="imagem" class="p-0 m-0 " fluid/>

                <div class="container-texto d-flex justify-content-center align-items-center p-5">
                    <transition name="fade">
                        <h1 v-show="upHere">{{ conteudo.titulo }}</h1>
                    </transition>
                </div>

            </div>
        </router-link>

    </div>
</template>

<script>
export default {
    name: "QuadradoComponent",
    data() {
        return {
            upHere: false,
        }
    },
    props: {
        conteudo: Object,
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
@import "@/main.scss";

div {

    div {
        position: relative;

        .container-texto {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;

            h1 {
                color: $cor-clara;
            }
        }
    }
}

.teste {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $cor-escura-transparente-75;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
<template>
    <b-row class="p-5 m-0 justify-content-center align-items-center">
        <h1 class="p-0 m-0">Projetos</h1>

        <instagram-component/>
    </b-row>
</template>

<script>
import IconesComponent from "@/components/icones/icones-component";
import InstagramComponent from "@/components/social/InstagramComponent";

export default {
    name: "ProjetosView",
    components: {InstagramComponent, IconesComponent},

    created() {
        document.title = 'Isadora Piana - Projetos';
    }
}
</script>

<style lang="scss" scoped>
</style>